import { Dispatch, SetStateAction, useCallback } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";

import { TableRowInfoToHighlight } from "@sellernote/_shared/src/headlessComponents/table/useTable";
import { FULFILLMENT_PACKING_ATOMS } from "@sellernote/_shared/src/states/fulfillment/packing";
import { InvoiceItem } from "@sellernote/_shared/src/types/fulfillment/packing";
import {
  getDetailOfSku,
  getLastPackingNo,
  getPackingInfoOfPackingNo,
  getPrevTotalQuantity,
} from "@sellernote/_shared/src/utils/fulfillment/packing";

export default function useSetSkuScanningResult({
  setRowInfoToHighlight,
}: {
  setRowInfoToHighlight: Dispatch<
    SetStateAction<TableRowInfoToHighlight | undefined>
  >;
}) {
  const setInvoiceDetailList = useSetRecoilState(
    FULFILLMENT_PACKING_ATOMS.INVOICE_DETAIL_LIST
  );
  const [packingInfoList, setPackingInfoList] = useRecoilState(
    FULFILLMENT_PACKING_ATOMS.PACKING_INFO_LIST
  );
  const setSelectedPackingNo = useSetRecoilState(
    FULFILLMENT_PACKING_ATOMS.SELECTED_PACKING_NO
  );
  const setIsScannedSkuIdComplete = useSetRecoilState(
    FULFILLMENT_PACKING_ATOMS.IS_SCANNED_SKU_ID_COMPLETE
  );

  const setSkuScanningResult = useCallback(
    ({
      invoiceDetailOfScannedResult,
      scannedSkuId,
      scannedBarcode,
    }: {
      invoiceDetailOfScannedResult: InvoiceItem;
      scannedSkuId: number | undefined;
      scannedBarcode?: string; // useSelectDuplicateBarcode에서는 선택된 skuId만 존재하기 때문에 이를 전달하지 않는다.
    }) => {
      const lastPackingNo = getLastPackingNo(packingInfoList);

      /** INVOICE_DETAIL_LIST */
      setInvoiceDetailList((prevInvoiceDetailList) => {
        const newInvoiceDetail = { ...invoiceDetailOfScannedResult };

        newInvoiceDetail.currentQty += 1;

        const packingNoList = newInvoiceDetail.packingNo.split(", ");
        const isEmptyPackingNo =
          packingNoList.length === 1 && !packingNoList[0];
        const isDifferentFromLastPackingNo =
          packingNoList[packingNoList.length - 1] !== String(lastPackingNo);
        if (isEmptyPackingNo) {
          newInvoiceDetail.packingNo = String(lastPackingNo);
        } else if (isDifferentFromLastPackingNo) {
          newInvoiceDetail.packingNo = `${newInvoiceDetail.packingNo}, ${lastPackingNo}`;
        }

        return prevInvoiceDetailList.map((prevInvoiceDetail) =>
          prevInvoiceDetail.skuId === scannedSkuId ||
          prevInvoiceDetail.skuBarcode === scannedBarcode
            ? newInvoiceDetail
            : prevInvoiceDetail
        );
      });

      /** PACKING_INFO_LIST */
      setPackingInfoList((prevPackingInfoList) => {
        const lastPackingInfo = getPackingInfoOfPackingNo({
          packingInfoList: prevPackingInfoList,
          packingNo: "last",
        });
        const packingDetailOfScannedSkuId = getDetailOfSku({
          list: lastPackingInfo.packingDetailList,
          skuId: scannedSkuId,
          skuBarcode: scannedBarcode,
        });

        if (packingDetailOfScannedSkuId) {
          const newPackingDetail = { ...packingDetailOfScannedSkuId };

          newPackingDetail.currentQty += 1;

          return [
            ...prevPackingInfoList.slice(0, -1),
            {
              ...lastPackingInfo,
              packingDetailList: lastPackingInfo.packingDetailList.map(
                (prevPackingDetailItem) =>
                  prevPackingDetailItem.skuId === scannedSkuId ||
                  prevPackingDetailItem.skuBarcode === scannedBarcode
                    ? newPackingDetail
                    : prevPackingDetailItem
              ),
            },
          ];
        } else {
          const prevTotalQuantity = getPrevTotalQuantity({
            packingInfoList,
            skuId: scannedSkuId,
            skuBarcode: scannedBarcode,
          });
          const {
            skuId,
            skuBarcode,
            itemName,
            quantity,
            bufferPackageList,
            tapePackage,
          } = invoiceDetailOfScannedResult;

          return [
            ...prevPackingInfoList.slice(0, -1),
            {
              ...lastPackingInfo,
              packingDetailList: [
                ...lastPackingInfo.packingDetailList,
                {
                  skuId,
                  skuBarcode,
                  itemName,
                  currentQty: 1,
                  quantity: quantity - prevTotalQuantity,
                  bufferPackageList,
                  tapePackage,
                  isActiveDeleteButton: true,
                },
              ],
            },
          ];
        }
      });

      // 어떤 포장탭이 선택된 상태이더라도, 상품스캔을 하면 카운팅이 되어야하는 마지막 포장탭이 선택되도록 함
      setSelectedPackingNo(lastPackingNo);

      // ! 취소관리 위한 임시 확인용
      const isScannedSkuIdComplete =
        invoiceDetailOfScannedResult.currentQty + 1 ===
        invoiceDetailOfScannedResult.quantity;
      if (isScannedSkuIdComplete) setIsScannedSkuIdComplete(true);

      setRowInfoToHighlight({ rowKey: invoiceDetailOfScannedResult.skuId });
    },
    [
      packingInfoList,
      setInvoiceDetailList,
      setIsScannedSkuIdComplete,
      setPackingInfoList,
      setRowInfoToHighlight,
      setSelectedPackingNo,
    ]
  );

  return {
    setSkuScanningResult,
  };
}
