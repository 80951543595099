import { useCallback, useMemo, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";

import Modal from "@sellernote/_shared/src/componentsToMoveToV1/Modal";
import { TableRowInfoToHighlight } from "@sellernote/_shared/src/headlessComponents/table/useTable";
import useResetPackingStates from "@sellernote/_shared/src/hooks/fulfillment/useResetPackingStates";
import PACKING_QUERY from "@sellernote/_shared/src/queries/fulfillment/PACKING_QUERY";
import SCAN_QUERY from "@sellernote/_shared/src/queries/fulfillment/SCAN_QUERY";
import {
  FULFILLMENT_PACKING_ATOMS,
  FULFILLMENT_PACKING_SELECTORS,
} from "@sellernote/_shared/src/states/fulfillment/packing";

import useCheckHasOutSidePackage from "hooks/packing/useCheckHasOutSidePackage";
import useScanActionBarcode from "hooks/packing/useScanActionBarcode";
import useCheckPrintPackingLabel from "pages/packing/CompletePacking/useCheckPrintPackingLabel";

export default function useCompletePacking({
  setRowInfoToHighlight,
}: {
  setRowInfoToHighlight: React.Dispatch<
    React.SetStateAction<TableRowInfoToHighlight | undefined>
  >;
}) {
  const [isVisibleCompletePackingModal, setIsVisibleCompletePackingModal] =
    useState(false);
  const [isVisiblePrintInvoiceModal, setIsVisiblePrintInvoiceModal] =
    useState(false);

  const isActiveCompletePackingButton = useRecoilValue(
    FULFILLMENT_PACKING_SELECTORS.IS_ACTIVE_COMPLETE_PACKING_BUTTON
  );
  const { companyName, teamName, teamId, shippingId } = useRecoilValue(
    FULFILLMENT_PACKING_ATOMS.INVOICE_SUMMARY
  );
  const boxesToComplete = useRecoilValue(
    FULFILLMENT_PACKING_SELECTORS.BOXES_TO_COMPLETE
  );
  const invoiceNoOfFirstPacking = useRecoilValue(
    FULFILLMENT_PACKING_SELECTORS.INVOICE_NO_OF_FIRST_PACKING
  );
  const isNeedToCheckPrintPackingLabel = useRecoilValue(
    FULFILLMENT_PACKING_ATOMS.IS_NEED_TO_CHECK_PRINT_PACKING_LABEL
  );

  const setCanceledInvoiceNo = useSetRecoilState(
    FULFILLMENT_PACKING_ATOMS.CANCELED_INVOICE_NO
  );

  const { resetPackingStates } = useResetPackingStates();

  const handleAfterPrintInvoice = useCallback(() => {
    resetPackingStates();
    setIsVisiblePrintInvoiceModal(false);
  }, [resetPackingStates]);

  const { setIsVisibleCheckPrintPackingLabelModal, CheckPrintPackingLabel } =
    useCheckPrintPackingLabel({
      resetPackingStates: handleAfterPrintInvoice,
    });

  const actionAfterCompletePacking = useCallback(() => {
    if (isNeedToCheckPrintPackingLabel) {
      setIsVisibleCheckPrintPackingLabelModal(true);
      return;
    }

    resetPackingStates();
  }, [
    isNeedToCheckPrintPackingLabel,
    resetPackingStates,
    setIsVisibleCheckPrintPackingLabelModal,
  ]);

  const {
    mutate: interpretBarcode,
    reset: resetInterpretBarcode,
    ResponseHandler: ResponseHandlerOfCheckingCancelingInvoice,
  } = SCAN_QUERY.useInterpretBarcode<"shipping">({
    isToCheckForCanceling: true,
    isBarcodeModal: true,
  });

  const {
    mutate: completePacking,
    reset: resetCompletePacking,
    ResponseHandler: ResponseHandlerOfCompletingPacking,
  } = PACKING_QUERY.useCompletePacking({ shippingId });

  const {
    mutate: updateItemNameForPrintingInvoice,
    reset: resetUpdateItemNameForPrintingInvoice,
    ResponseHandler: ResponseHandlerOfUpdatingItemNameForPrintingInvoice,
  } = PACKING_QUERY.useUpdateItemNameForPrintingInvoice({
    shippingId,
    type: "completePacking",
    actionAfterCompletePacking,
  });

  // 외포장재 선택 여부 체크 -> 취소 여부 체크 -> 포장마감
  const checkIsCanceledInvoice = useCallback(() => {
    interpretBarcode(
      {
        type: "shipping",
        barCode: invoiceNoOfFirstPacking,
      },
      {
        onSuccess: ({ data: { shippingStatus } }) => {
          if (shippingStatus === "cancel") {
            setCanceledInvoiceNo(invoiceNoOfFirstPacking);
            return;
          }

          setIsVisibleCompletePackingModal(true);
        },
      }
    );
  }, [invoiceNoOfFirstPacking, interpretBarcode, setCanceledInvoiceNo]);

  const { checkHasOutSidePackage, CheckHasOutSidePackageModal } =
    useCheckHasOutSidePackage({
      onSuccess: checkIsCanceledInvoice,
    });

  // 송장출력을 끝냈다는 트리거는 송장출력 모달을 닫는 순간
  const handlePrintInvoiceModalClose = useCallback(() => {
    if (isNeedToCheckPrintPackingLabel) {
      setIsVisibleCheckPrintPackingLabelModal(true);
      return;
    }

    handleAfterPrintInvoice();
  }, [
    isNeedToCheckPrintPackingLabel,
    handleAfterPrintInvoice,
    setIsVisibleCheckPrintPackingLabelModal,
  ]);

  const handlePackingComplete = useCallback(() => {
    const isOnlyOneBox = boxesToComplete.length === 1;

    completePacking(
      { boxes: boxesToComplete },
      {
        onSuccess: () => {
          setIsVisibleCompletePackingModal(false);
          setRowInfoToHighlight(undefined);

          if (!isOnlyOneBox) {
            updateItemNameForPrintingInvoice(undefined, {
              onSuccess: () => {
                setIsVisiblePrintInvoiceModal(true);
              },
            });
            return;
          }

          actionAfterCompletePacking();
        },
      }
    );
  }, [
    boxesToComplete,
    completePacking,
    setRowInfoToHighlight,
    actionAfterCompletePacking,
    updateItemNameForPrintingInvoice,
  ]);

  const CompletePackingModal = useMemo(
    () => (
      <Modal
        barcodeValues={{
          actionPositive: "O_COM_PAC",
          actionNegative: "X_COM_PAC",
        }}
        active={isVisibleCompletePackingModal}
        uiType="content"
        title={
          <>
            {`${companyName} (${teamName} / ${teamId})`}
            <br />
            {shippingId}(출고요청번호)
          </>
        }
        body="포장을 마감하시겠습니까?"
        actionPositive={{
          label: "예",
          handleClick: handlePackingComplete,
        }}
        actionNegative={{
          label: "아니오",
          handleClick: () => setIsVisibleCompletePackingModal(false),
        }}
      />
    ),
    [
      companyName,
      handlePackingComplete,
      isVisibleCompletePackingModal,
      shippingId,
      teamId,
      teamName,
    ]
  );

  useScanActionBarcode({
    actionBarcode: "COM_PAC",
    actionFn: checkHasOutSidePackage,
  });
  useScanActionBarcode({
    actionBarcode: "H_INT_BAR",
    actionFn: resetInterpretBarcode,
  });
  useScanActionBarcode({
    actionBarcode: "H_COM_PAC",
    actionFn: resetCompletePacking,
  });
  useScanActionBarcode({
    actionBarcode: "O_COM_PAC",
    actionFn: handlePackingComplete,
  });
  useScanActionBarcode({
    actionBarcode: "X_COM_PAC",
    actionFn: () => setIsVisibleCompletePackingModal(false),
  });
  useScanActionBarcode({
    actionBarcode: "H_UPD_NAME",
    actionFn: resetUpdateItemNameForPrintingInvoice,
  });

  return {
    checkHasOutSidePackage,
    isActiveCompletePackingButton,

    isVisiblePrintInvoiceModal,
    handlePrintInvoiceModalClose,

    CheckHasOutSidePackageModal,
    CompletePackingModal,
    CheckPrintPackingLabel,

    ResponseHandlerOfCheckingCancelingInvoice,
    ResponseHandlerOfCompletingPacking,
    ResponseHandlerOfUpdatingItemNameForPrintingInvoice,
  };
}
