import { useCallback } from "react";
import { useRecoilValue } from "recoil";
import { PACKING_ATOMS } from "states/packing";

export default function WarningAudio() {
  const isScannedBarcodeNotInPackingList = useRecoilValue(
    PACKING_ATOMS.IS_SCANNED_BARCODE_NOT_IN_PACKING_LIST
  );

  const warningAudioRef = useCallback(
    (node: HTMLAudioElement | null) => {
      if (isScannedBarcodeNotInPackingList) {
        node && (node.loop = true);
        node?.play();
      } else {
        node?.pause();
        node && (node.currentTime = 0);
      }
    },
    [isScannedBarcodeNotInPackingList]
  );

  return <audio src="warning.wav" ref={warningAudioRef} />;
}
