import { useCallback, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";

import Modal from "@sellernote/_shared/src/componentsToMoveToV1/Modal";

import useScanActionBarcode from "hooks/packing/useScanActionBarcode";

// #5618참고하기: https://dev.azure.com/shipda/Shipda/_workitems/edit/5618
export default function useCheckPrintPackingLabel({
  resetPackingStates,
}: {
  resetPackingStates: () => void;
}) {
  const [
    isVisibleCheckPrintPackingLabelModal,
    setIsVisibleCheckPrintPackingLabelModal,
  ] = useState(false);

  const history = useHistory();

  const handlePrintPackingLabelConfirm = useCallback(() => {
    history.push("/packing/print");
    setIsVisibleCheckPrintPackingLabelModal(false);
    resetPackingStates();
  }, [history, resetPackingStates]);

  const handlePrintPackingLabelCancel = useCallback(() => {
    setIsVisibleCheckPrintPackingLabelModal(false);
    resetPackingStates();
  }, [resetPackingStates]);

  const CheckPrintPackingLabel = useMemo(
    () => (
      <Modal
        barcodeValues={{
          actionPositive: "O_PRI_PAC_LAB",
          actionNegative: "X_PRI_PAC_LAB",
        }}
        active={isVisibleCheckPrintPackingLabelModal}
        uiType="titleOnly"
        title="패킹 라벨을 출력하시겠습니까?"
        actionPositive={{
          label: "예",
          handleClick: handlePrintPackingLabelConfirm,
        }}
        actionNegative={{
          label: "아니오",
          handleClick: handlePrintPackingLabelCancel,
        }}
      />
    ),
    [
      isVisibleCheckPrintPackingLabelModal,
      handlePrintPackingLabelConfirm,
      handlePrintPackingLabelCancel,
    ]
  );

  useScanActionBarcode({
    actionBarcode: "O_PRI_PAC_LAB",
    actionFn: handlePrintPackingLabelConfirm,
  });
  useScanActionBarcode({
    actionBarcode: "X_PRI_PAC_LAB",
    actionFn: handlePrintPackingLabelCancel,
  });

  return {
    setIsVisibleCheckPrintPackingLabelModal,

    CheckPrintPackingLabel,
  };
}
