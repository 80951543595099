import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import {
  ADD_NEXT_PACKING_RES,
  COMPLETE_PACKING_REQ,
  COMPLETE_PACKING_RES,
  GET_INVOICE_PRINTING_DATA_REQ_PATH_PARAMS,
  OUT_SIDE_PACKAGE_RES,
  REPORT_PROBLEM_REQ,
  REPORT_PROBLEM_RES,
} from "@sellernote/_shared/src/api-interfaces/boful-api/packing";
import { QueryResponseHandlerCustomMessage } from "@sellernote/_shared/src/components/QueryResponseHandler";
import { InputSelectOption } from "@sellernote/_shared/src/headlessComponents/input/useInputSelect";
import {
  MutationSideEffectType,
  useAppMutation,
  useAppQueryWithQueryKeyFactory,
} from "@sellernote/_shared/src/services/query";
import {
  FULFILLMENT_PACKING_ATOMS,
  FULFILLMENT_PACKING_SELECTORS,
} from "@sellernote/_shared/src/states/fulfillment/packing";
import {
  InvoiceItem,
  OutSidePackageProviderValue,
  PackingDetailItem,
  PackingInfoItem,
  PackingInvoice,
  PackingItem,
} from "@sellernote/_shared/src/types/fulfillment/packing";
import {
  getBufferAndTapePackage,
  getDetailOfSku,
  getOutSidePackageList,
  getPackingInfoOfPackingNo,
  getPrevTotalQuantity,
  listOfCustomersWhoNeedToCheckPackingLabelPrinting,
  providerOptionDict,
  restoreInvoiceDetailList,
  sortOutSidePackageList,
} from "@sellernote/_shared/src/utils/fulfillment/packing";

import useResetPackingStates from "../../hooks/fulfillment/useResetPackingStates";
import { noop } from "../../utils/common/etc";

import { GET_INVOICE_PRINTING_DATA_RES } from "../../api-interfaces/boful-api/shipping";

export const PACKING_QUERY_KEY_GEN = {
  all: () => [{ scope: "fulfillment/PACKING_QUERY" }] as const,
  outSidePackageListOfShipda: () =>
    [
      {
        ...PACKING_QUERY_KEY_GEN.all()[0],
        subScope: "outSidePackageListOfShipda",
      },
    ] as const,
  getOutSidePackageListOfShipda: () =>
    // warehouseId로 가져오지 않게되어서 필요 없으나, 나중에 parameter를 따로 받아올 수도 있으므로 일단 유지함
    [
      {
        ...PACKING_QUERY_KEY_GEN.outSidePackageListOfShipda()[0],
      },
    ] as const,
  outSidePackageListOfCustomer: () =>
    [
      {
        ...PACKING_QUERY_KEY_GEN.all()[0],
        subScope: "OutSidePackageListOfCustomer",
      },
    ] as const,
  getOutSidePackageListOfCustomer: (params: { teamId: number | undefined }) =>
    [
      {
        ...PACKING_QUERY_KEY_GEN.outSidePackageListOfCustomer()[0],
        ...params,
      },
    ] as const,
};

function useGetOutsidePackageListOfShipda({
  enabled,
}: {
  enabled: boolean; // Query 요청에 실패한 경우 바코드 모달을 닫고, 재요청을 막기 하기 위해서 enabled 값을 받아와서 사용
}) {
  const setOutSidePackage = useSetRecoilState(
    FULFILLMENT_PACKING_ATOMS.OUT_SIDE_PACKAGE
  );

  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof PACKING_QUERY_KEY_GEN.getOutSidePackageListOfShipda>,
    OUT_SIDE_PACKAGE_RES
  >({
    queryKey: PACKING_QUERY_KEY_GEN.getOutSidePackageListOfShipda(),
    requestOptions: {
      method: "get",
      path: `/material/manager?provider=shipda&packageCategory=outside`,
    },

    enabled,

    onSuccess: (oustSidePackageList) => {
      const sortedOutSidePackageListOfShipda = sortOutSidePackageList(
        oustSidePackageList.list
      );

      setOutSidePackage((prevOutsidePackage) => ({
        ...prevOutsidePackage,
        outSidePackageListOfShipda: getOutSidePackageList(
          sortedOutSidePackageListOfShipda
        ),
      }));
    },

    failureModalInfo: {
      customizeMessage: () => ({
        title: `쉽다 외포장재 리스트 조회 중에 오류가 발생했습니다.`,
      }),
      barcodeValue: "H_SHI_OUT",
    },
  });

  return { ...queryResult };
}

function useGetOutsidePackageListOfCustomer({
  teamId,
  enabled,
}: {
  teamId: number | undefined;
  enabled: boolean; // Query 요청에 실패한 경우 바코드 모달을 닫고, 재요청을 막기 하기 위해서 enabled 값을 받아와서 사용
}) {
  const setOutSidePackage = useSetRecoilState(
    FULFILLMENT_PACKING_ATOMS.OUT_SIDE_PACKAGE
  );

  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof PACKING_QUERY_KEY_GEN.getOutSidePackageListOfCustomer>,
    OUT_SIDE_PACKAGE_RES
  >({
    queryKey: PACKING_QUERY_KEY_GEN.getOutSidePackageListOfCustomer({ teamId }),
    requestOptions: {
      method: "get",
      path: `/material/manager?provider=customer&packageCategory=outside&teamId=${teamId}`,
    },

    enabled,

    onSuccess: (oustSidePackageList) => {
      const sortedOutSidePackageListOfCustomer = sortOutSidePackageList(
        oustSidePackageList.list
      );

      setOutSidePackage((prevOutsidePackage) => ({
        ...prevOutsidePackage,
        outSidePackageListOfCustomer: getOutSidePackageList(
          sortedOutSidePackageListOfCustomer
        ),
      }));
    },

    failureModalInfo: {
      customizeMessage: () => ({
        title: `고객사 외포장재 리스트 조회 중에 오류가 발생했습니다.`,
      }),
      barcodeValue: "H_CUS_OUT",
    },
  });

  return { ...queryResult };
}

function useStartPacking({ type }: { type: "scan" | "directInput" }) {
  const setIsWorking = useSetRecoilState(FULFILLMENT_PACKING_ATOMS.IS_WORKING);
  const setInvoiceSummary = useSetRecoilState(
    FULFILLMENT_PACKING_ATOMS.INVOICE_SUMMARY
  );
  const setOutSidePackage = useSetRecoilState(
    FULFILLMENT_PACKING_ATOMS.OUT_SIDE_PACKAGE
  );
  const setInvoiceDetailList = useSetRecoilState(
    FULFILLMENT_PACKING_ATOMS.INVOICE_DETAIL_LIST
  );
  const setPackingInfoList = useSetRecoilState(
    FULFILLMENT_PACKING_ATOMS.PACKING_INFO_LIST
  );
  const setRequestMemoList = useSetRecoilState(
    FULFILLMENT_PACKING_ATOMS.REQUEST_MEMO_LIST
  );
  const setIsStatusForScanningCompletedInvoice = useSetRecoilState(
    FULFILLMENT_PACKING_ATOMS.IS_STATUS_FOR_SCANNING_COMPLETED_INVOICE
  );
  const setIsCompletedInvoice = useSetRecoilState(
    FULFILLMENT_PACKING_ATOMS.IS_COMPLETED_INVOICE
  );
  const setPackings = useSetRecoilState(FULFILLMENT_PACKING_ATOMS.PACKINGS);
  const setIsNeedToCheckPrintPackingLabel = useSetRecoilState(
    FULFILLMENT_PACKING_ATOMS.IS_NEED_TO_CHECK_PRINT_PACKING_LABEL
  );
  const setDataForPackingLabel = useSetRecoilState(
    FULFILLMENT_PACKING_ATOMS.DATA_FOR_PACKING_LABEL
  );

  const mutation = useAppMutation<
    unknown,
    PackingInvoice,
    { invoiceNo: string }
  >({
    requestOptions: {
      method: "patch",
      path: ({ invoiceNo }) => `/shipping/worker/packing/start/${invoiceNo}`,
      apiType: "BofulDefault",
    },

    onSuccess: (packingInvoiceData) => {
      if (packingInvoiceData.shippingStatus === "cancel") return;

      const isCompletedPackingInvoice =
        packingInvoiceData.wmsStatus === "ready";

      /** IS_STATUS_FOR_SCANNING_COMPLETED_INVOICE */
      setIsStatusForScanningCompletedInvoice(isCompletedPackingInvoice);

      /** IS_COMPLETED_INVOICE */
      setIsCompletedInvoice(isCompletedPackingInvoice);

      /** IS_WORKING */
      isCompletedPackingInvoice ? setIsWorking(false) : setIsWorking(true);

      /** INVOICE_SUMMARY */
      setInvoiceSummary({
        companyName: packingInvoiceData.team.company ?? "-",
        teamName: packingInvoiceData.team.name ?? "-",
        teamId: packingInvoiceData.teamId,
        shippingId: packingInvoiceData.id,
        deliveryType: packingInvoiceData.deliveryType,
      });

      /** OUT_SIDE_PACKAGE */
      let selectedProvider: InputSelectOption<OutSidePackageProviderValue>; // PACKING_INFO_LIST와 연동하기 위함
      const providerList = new Set(
        packingInvoiceData.items
          .flatMap((item) => item.sku.packages)
          .map((packageItem) => packageItem.provider)
      );
      const hasSkuLinkedOutSidePackageOfCustomer = providerList.size > 1;
      // provider의 기본값은 고객사를 우선으로 한다.
      if (hasSkuLinkedOutSidePackageOfCustomer) {
        setOutSidePackage((prevOutsidePackage) => ({
          ...prevOutsidePackage,
          selectedProvider: providerOptionDict.customer,
        }));
        selectedProvider = providerOptionDict.customer;
      } else {
        setOutSidePackage((prevOutsidePackage) => ({
          ...prevOutsidePackage,
          selectedProvider: providerOptionDict.shipda,
        }));
        selectedProvider = providerOptionDict.shipda;
      }

      /** INVOICE_DETAIL_LIST */
      // SKU ID는 동일하지만, location이 다른 경우 별도의 item으로 생성되기 때문에 패킹화면에서 한꺼번에 보여주기 위해 합침
      const invoiceDetailList = packingInvoiceData.items
        .reduce<PackingItem[]>((prevItems, item) => {
          const indexOfDuplicateSKUId = prevItems.findIndex(
            (e) => e.skuId === item.skuId
          );

          if (indexOfDuplicateSKUId === -1) {
            return [...prevItems, item];
          } else {
            const prevItem = prevItems[indexOfDuplicateSKUId];
            prevItem.quantity += item.quantity;

            return prevItems;
          }
        }, [])
        .map<InvoiceItem>((item) => {
          const { bufferPackageList, tapePackage } =
            getBufferAndTapePackage(item);

          const packingNo = (packingInvoiceData.packings ?? [])
            .flatMap((packingItem, indexOfPackingItem) =>
              packingItem.packingToSku.reduce<number[]>(
                (prevPackingNoList, packingToSkuItem) => {
                  if (packingToSkuItem.stockUnitId === item.skuId) {
                    return [...prevPackingNoList, indexOfPackingItem + 1];
                  }

                  return prevPackingNoList;
                },
                []
              )
            )
            .join(", ");

          return {
            packingNo: isCompletedPackingInvoice ? packingNo : "", // 기본값 빈 값 -> 포장 추천 시 추천 값
            locationId: item.locationId,
            skuId: item.skuId,
            skuBarcode: item.sku.barCode?.toUpperCase() ?? "", // barCode의 기본값은 빈 문자열이었으나, nullable하게 변경됨 -> 이전과 동일한 동작을 위해 기본값을 빈 문자열로 만들어 줌
            itemName: item.sku.itemName,
            currentQty: isCompletedPackingInvoice ? item.quantity : 0,
            quantity: item.quantity,
            outSidePackageName:
              item.sku.packages.find(
                ({ packageCategory }) => packageCategory === "outside"
              )?.name ?? "-",
            bufferPackageList,
            tapePackage,
          };
        });
      setInvoiceDetailList(invoiceDetailList);

      /** PACKING_INFO_LIST */
      const initialPackingInfoList = [
        {
          packingItemId: -1,
          invoiceNo: packingInvoiceData.invoiceNo,
          outSidePackageProvider: selectedProvider,
          outSidePackage: { label: "", value: -1 },
          packingDetailList: [],
        },
      ];
      const packingInfoListForPrintingInvoice = (
        packingInvoiceData.packings ?? []
      ).map<PackingInfoItem>((packingItem) => {
        return {
          packingItemId: packingItem.id,
          invoiceNo: packingItem.invoiceNo,
          outSidePackageProvider: { label: "temp", value: "none" }, // 임시
          outSidePackage: { label: "", value: packingItem.outerPackagesId }, // 임시
          packingDetailList: packingItem.packingToSku.map<PackingDetailItem>(
            (packingToSkuItem) => {
              const skuInfo = packingInvoiceData.items.find(
                (item) => item.skuId === packingToSkuItem.stockUnitId
              );

              const { bufferPackageList, tapePackage } =
                getBufferAndTapePackage(skuInfo);

              return {
                skuId: packingToSkuItem.stockUnitId,
                skuBarcode: skuInfo?.sku.barCode?.toUpperCase() ?? "", // barCode의 기본값은 빈 문자열이었으나, nullable하게 변경됨 -> 이전과 동일한 동작을 위해 기본값을 빈 문자열로 만들어 줌
                itemName: skuInfo?.sku.itemName ?? "",
                currentQty: packingToSkuItem.qty,
                quantity: packingToSkuItem.qty,
                bufferPackageList,
                tapePackage,
                isActiveDeleteButton: false,
              };
            }
          ),
        };
      });
      setPackingInfoList(
        isCompletedPackingInvoice
          ? packingInfoListForPrintingInvoice
          : initialPackingInfoList
      );

      /** REQUEST_MEMO_LIST */
      setRequestMemoList(
        (packingInvoiceData.memo ?? []).map((memoItem) => memoItem.memo)
      );

      /** PACKINGS */
      setPackings(
        isCompletedPackingInvoice ? packingInvoiceData.packings ?? [] : []
      );

      /** IS_NEED_TO_CHECK_PRINT_PACKING_LABEL */
      setIsNeedToCheckPrintPackingLabel(
        !!listOfCustomersWhoNeedToCheckPackingLabelPrinting.get(
          packingInvoiceData.teamId
        )
      );

      /**  DATA_FOR_PACKING_LABEL */
      setDataForPackingLabel({
        orderNo: packingInvoiceData.orderNo,
        customerName: packingInvoiceData.customerName,
        customerPhone: packingInvoiceData.customerPhone,
      });
    },

    failureModalInfo: {
      customizeMessage: (failureInfo): QueryResponseHandlerCustomMessage => {
        const isScan = type === "scan";

        const errorMessageAsDefault = {
          title: `송장(QR)을  ${
            isScan ? "스캔" : "입력"
          }하는 중에 오류가 발생했습니다.`,
        };

        const getErrorMessageAsCode400 = ({
          errorCode,
        }: {
          errorCode: string | undefined;
        }) =>
          ({
            E4002: undefined,
            E4020: {
              messageType: "titleAndBody" as const,
              title: `올바른 송장(QR)을 ${isScan ? "스캔" : "입력"}해주세요.`,
              body: "피킹이 완료되지 않은 송장(QR)입니다.",
            },
            E4024: {
              messageType: "titleAndBody" as const,
              title: `올바른 송장(QR)을 ${isScan ? "스캔" : "입력"}해주세요.`,
              body: "출고가 완료된 송장(QR)입니다.",
            },
            E4025: {
              messageType: "titleAndBody" as const,
              title: `올바른 송장(QR)을 ${isScan ? "스캔" : "입력"}해주세요.`,
              body: "반품된 송장(QR)입니다.",
            },
          }[errorCode ?? ""] ?? errorMessageAsDefault);

        const getErrorMessage = ({
          code,
          errorCode,
        }: {
          code: number | undefined;
          errorCode: string | undefined;
        }) =>
          ({
            400: getErrorMessageAsCode400({ errorCode }),
            404: {
              messageType: "titleAndBody" as const,
              title: `올바른 송장(QR)을 ${isScan ? "스캔" : "입력"}해주세요.`,
              body: "해당 출고 건이 없습니다. ",
            },
          }[code ?? 0] ?? errorMessageAsDefault);

        return getErrorMessage({
          code: failureInfo?.code,
          errorCode: failureInfo?.errorCode,
        });
      },
      barcodeValue: "H_STA_PAC",
    },
  });

  return { ...mutation };
}

function useAddNextPacking() {
  const setSelectedPackingNo = useSetRecoilState(
    FULFILLMENT_PACKING_ATOMS.SELECTED_PACKING_NO
  );
  const setPackingInfoList = useSetRecoilState(
    FULFILLMENT_PACKING_ATOMS.PACKING_INFO_LIST
  );
  const outSidePackage = useRecoilValue(
    FULFILLMENT_PACKING_ATOMS.OUT_SIDE_PACKAGE
  );
  const lastPackingNo = useRecoilValue(
    FULFILLMENT_PACKING_SELECTORS.LAST_PACKING_NO
  );

  const mutation = useAppMutation<
    unknown,
    ADD_NEXT_PACKING_RES,
    { shippingId: number }
  >({
    requestOptions: {
      method: "post",
      path: ({ shippingId }) => `/shipping/worker/packing/${shippingId}`,
      apiType: "BofulDefault",
    },

    onSuccess: (addedPackingData) => {
      /** lastPackingInfo의 정보를 변경하고, 새로운 packingInfo를 추가함 */
      setPackingInfoList((prevPackingInfoList) => {
        const lastPackingInfo = getPackingInfoOfPackingNo({
          packingInfoList: prevPackingInfoList,
          packingNo: "last",
        });
        const newLastPackingDetailList = lastPackingInfo.packingDetailList.map(
          (packingDetailItem) => ({
            ...packingDetailItem,
            isActiveDeleteButton: false,
            quantity: packingDetailItem.currentQty,
          })
        );
        const newPackingInfoList = [
          ...prevPackingInfoList.slice(0, -1),
          {
            ...lastPackingInfo,
            packingDetailList: newLastPackingDetailList,
          },
        ];

        return [
          ...newPackingInfoList,
          {
            packingItemId: addedPackingData.id,
            invoiceNo: addedPackingData.invoiceNo,
            outSidePackageProvider:
              outSidePackage.selectedProvider ?? providerOptionDict.shipda,
            // 포장 추가 시에는 사이즈가 제일 작은 외포장재를 기본값으로 설정함
            outSidePackage:
              outSidePackage.selectedProvider?.value === "shipda"
                ? outSidePackage.outSidePackageListOfShipda[0]
                : outSidePackage.selectedProvider?.value === "none"
                ? { label: "포장불필요", value: "none" }
                : outSidePackage.outSidePackageListOfCustomer[0],
            packingDetailList: [],
          },
        ];
      });

      /** tabFilter - 새로운 packingInfo를 선택하도록 변경 */
      setSelectedPackingNo(lastPackingNo + 1);
    },

    failureModalInfo: {
      customizeMessage: () => ({
        title: "다음 포장으로 요청 중에 오류가 발생했습니다.",
      }),
      barcodeValue: "H_ADD_NEXT_PAC",
    },
  });

  return { ...mutation };
}

function useDeleteLastPacking({
  type,
}: {
  type: "lastPacking" | "packedItem";
}) {
  const setSelectedPackingNo = useSetRecoilState(
    FULFILLMENT_PACKING_ATOMS.SELECTED_PACKING_NO
  );
  const [invoiceDetailList, setInvoiceDetailList] = useRecoilState(
    FULFILLMENT_PACKING_ATOMS.INVOICE_DETAIL_LIST
  );
  const [packingInfoList, setPackingInfoList] = useRecoilState(
    FULFILLMENT_PACKING_ATOMS.PACKING_INFO_LIST
  );
  const lastPackingNo = useRecoilValue(
    FULFILLMENT_PACKING_SELECTORS.LAST_PACKING_NO
  );
  const invoiceNoOfLastPacking = useRecoilValue(
    FULFILLMENT_PACKING_SELECTORS.INVOICE_NO_OF_LAST_PACKING
  );

  const mutation = useAppMutation<void, { result: string }>({
    requestOptions: {
      method: "delete",
      path: `/shipping/worker/packing/${invoiceNoOfLastPacking}`,
      apiType: "BofulDefault",
    },

    onSuccess: () => {
      // packedItem을 삭제 후 실행 될 때는 이미 InvoiceDetailList을 되돌렸기 때문에 실행하지 않는다.
      if (type === "lastPacking") {
        /** InvoiceDetailList 데이터 되돌리기 */
        const lastPackingDetailList = getPackingInfoOfPackingNo({
          packingInfoList,
          packingNo: "last",
        }).packingDetailList;
        const skuIdListToDelete = lastPackingDetailList.map(
          (lastPackingDetailItem) => lastPackingDetailItem.skuId
        );

        restoreInvoiceDetailList({
          packingDetailList: lastPackingDetailList,
          skuIdListToDelete,
          setInvoiceDetailList,
        });
      }

      /** PackingDetail의 마지막 탭 삭제 */
      setPackingInfoList((prevPackingInfoList) =>
        prevPackingInfoList.slice(0, -1)
      );

      /** 삭제 이후 새롭게 마지막 탭이 되는 packingInfo를 되돌리기(수량, 삭제버튼 활성화) */
      // 최근포장삭제는 포장이 2개 이상일 때만 가능하므로, lastPackingNo - 1은 항상 존재함
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const lastPackingInfoAfterDelete = getPackingInfoOfPackingNo({
        packingInfoList: packingInfoList,
        packingNo: lastPackingNo - 1,
      })!;
      const skuIdListOfLastPackingInfoAfterDelete =
        lastPackingInfoAfterDelete.packingDetailList.map(
          (packingDetailItem) => packingDetailItem.skuId
        );

      const restorePackingInfoList = (
        skuIdListOfLastPackingInfoAfterDelete: number[]
      ) => {
        setPackingInfoList((prevPackingInfoList) => {
          return [
            ...prevPackingInfoList.slice(0, -1),
            {
              ...lastPackingInfoAfterDelete,
              packingDetailList:
                lastPackingInfoAfterDelete.packingDetailList.map(
                  (packingDetailItem) => {
                    const isSkuIdOfLastPackingInfoAfterDelete =
                      skuIdListOfLastPackingInfoAfterDelete.includes(
                        packingDetailItem.skuId
                      );
                    if (isSkuIdOfLastPackingInfoAfterDelete) {
                      const quantityOfSkuId =
                        getDetailOfSku({
                          list: invoiceDetailList,
                          skuId: packingDetailItem.skuId,
                        })?.quantity ?? 0;
                      const prevTotalQuantityOfSkuId = getPrevTotalQuantity({
                        packingInfoList,
                        skuId: packingDetailItem.skuId,
                        isDeleteLastPacking: true,
                      });

                      return {
                        ...packingDetailItem,
                        quantity: quantityOfSkuId - prevTotalQuantityOfSkuId,
                        isActiveDeleteButton: true,
                      };
                    }

                    return packingDetailItem;
                  }
                ),
            },
          ];
        });
      };

      restorePackingInfoList(skuIdListOfLastPackingInfoAfterDelete);

      /** tabFilter - 새로운 packingInfo를 선택하도록 변경 */
      setSelectedPackingNo(lastPackingNo - 1);
    },

    failureModalInfo: {
      customizeMessage: () => ({
        title: "최근포장삭제 중에 오류가 발생했습니다.",
      }),
      barcodeValue: "H_DEL_LAST_PAC",
    },
  });

  return { ...mutation };
}

function useCompletePacking({
  shippingId,
}: {
  shippingId: number | undefined;
}) {
  const invoiceNoOfFirstPacking = useRecoilValue(
    FULFILLMENT_PACKING_SELECTORS.INVOICE_NO_OF_FIRST_PACKING
  );
  const setPackings = useSetRecoilState(FULFILLMENT_PACKING_ATOMS.PACKINGS);
  const boxesToComplete = useRecoilValue(
    FULFILLMENT_PACKING_SELECTORS.BOXES_TO_COMPLETE
  );

  const isOnlyOneBox = boxesToComplete.length === 1;

  const mutation = useAppMutation<COMPLETE_PACKING_REQ, COMPLETE_PACKING_RES>({
    requestOptions: {
      method: "patch",
      path: `/shipping/worker/packing/complete/${shippingId}`,
      apiType: "BofulDefault",
    },

    onSuccess: ({ packings }) =>
      setPackings(
        isOnlyOneBox
          ? // 원송장은 제외하고 출력하기 위함
            packings.filter(
              (packingItem) => packingItem.invoiceNo !== invoiceNoOfFirstPacking
            )
          : packings
      ),

    failureModalInfo: {
      customizeMessage: (failureInfo) => ({
        title:
          failureInfo?.errorCode === "E4001"
            ? "출고해야할 수량보다 재고가 더 적습니다."
            : "포장마감 중에 오류가 발생했습니다.",
      }),
      barcodeValue: "H_COM_PAC",
    },
  });

  return { ...mutation };
}

function useReportProblem() {
  const { resetPackingStates } = useResetPackingStates();

  const mutation = useAppMutation<REPORT_PROBLEM_REQ, REPORT_PROBLEM_RES>({
    requestOptions: {
      method: "post",
      path: `/report`,
      apiType: "BofulDefault",
    },

    onSuccess: resetPackingStates,

    failureModalInfo: {
      customizeMessage: () => ({
        title: `문제보고 중에 오류가 발생했습니다.`,
      }),
      barcodeValue: "H_REP_PRO",
    },
  });

  return { ...mutation };
}

function useUpdateItemNameForPrintingInvoice({
  shippingId,
  ...paramsByType
}: {
  shippingId: number | undefined;
} & (
  | {
      /** 포장 마감 후 */
      type: "completePacking";
      actionAfterCompletePacking: () => void;
    }
  | {
      /** 송장 수동 출력 */
      type: "printInvoice";
    }
)) {
  const mutation = useAppMutation<unknown, { result: "OK" }>({
    requestOptions: {
      method: "patch",
      path: `/parcel/item/invoice/${shippingId}`,
      apiType: "BofulDefault",
    },

    failureModalInfo: {
      customizeMessage: (failureInfo) => {
        if (failureInfo?.errorCode === "E4111") {
          return {
            title: "패킹이 완료되지 않은 출고건입니다.",
          };
        }

        return {
          title: `송장 출력을 위한 상품명 업데이트 중에 오류가 발생했습니다.`,
        };
      },

      handleConfirmFailure:
        // 포장 마감은 진행 되었으므로 재시도가 필요하다면, 송장을 재스캔해서 시도할 수 있도록 기존 프로세스를 진행함
        paramsByType.type === "completePacking"
          ? paramsByType.actionAfterCompletePacking
          : noop,

      barcodeValue: "H_UPD_NAME",
    },
  });

  return mutation;
}

function useGetInvoicePrintingData(
  sideEffectOptions?: MutationSideEffectType<
    unknown,
    GET_INVOICE_PRINTING_DATA_RES
  >
) {
  const mutation = useAppMutation<
    unknown,
    GET_INVOICE_PRINTING_DATA_RES,
    GET_INVOICE_PRINTING_DATA_REQ_PATH_PARAMS
  >({
    requestOptions: {
      method: "patch",
      path: ({ invoiceNo }) => `/parcel/print/${invoiceNo}`,
      apiType: "BofulDefault",
    },
    ...sideEffectOptions,

    failureModalInfo: {
      customizeMessage: () => ({
        title: `송장 인쇄 정보를 조회하는 중에 오류가 발생했습니다.`,
      }),
      barcodeValue: "H_GET_INV_PRI",
    },
  });

  return { ...mutation };
}

const PACKING_QUERY = {
  useGetOutsidePackageListOfShipda,
  useGetOutsidePackageListOfCustomer,

  useStartPacking,
  useAddNextPacking,
  useDeleteLastPacking,
  useCompletePacking,
  useReportProblem,

  useUpdateItemNameForPrintingInvoice,

  useGetInvoicePrintingData,
};
export default PACKING_QUERY;
