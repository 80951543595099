import { useCallback, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { IS_UNDER_PRODUCTION } from "@sellernote/_shared/src/constants";
import { TableRowInfoToHighlight } from "@sellernote/_shared/src/headlessComponents/table/useTable";
import {
  FULFILLMENT_PACKING_ATOMS,
  FULFILLMENT_PACKING_SELECTORS,
} from "@sellernote/_shared/src/states/fulfillment/packing";
import { useBrowserScan } from "@sellernote/_shared/src/utils/common/hook";

import useScanInvoice from "./useScanInvoice";
import useScanSku from "./useScanSku";

export default function usePacking() {
  const [rowInfoToHighlight, setRowInfoToHighlight] =
    useState<TableRowInfoToHighlight>();

  const isWorking = useRecoilValue(FULFILLMENT_PACKING_ATOMS.IS_WORKING);
  const isActiveNextPackingButton = useRecoilValue(
    FULFILLMENT_PACKING_SELECTORS.IS_ACTIVE_NEXT_PACKING_BUTTON
  );
  const isActiveDeleteLastPackingButton = useRecoilValue(
    FULFILLMENT_PACKING_SELECTORS.IS_ACTIVE_DELETE_LAST_PACKING_BUTTON
  );
  const isActiveCompletePackingButton = useRecoilValue(
    FULFILLMENT_PACKING_SELECTORS.IS_ACTIVE_COMPLETE_PACKING_BUTTON
  );

  const setCurrentActionBarcode = useSetRecoilState(
    FULFILLMENT_PACKING_ATOMS.CURRENT_ACTION_BARCODE
  );

  const { scanInvoice, ResponseHandlerOfStartingPacking } = useScanInvoice();
  const {
    checkIsCorrectSkuBarcode,

    ErrorMessageOfScanningSkuModal,
    ResponseHandlerOfCheckingCancelingInvoice,
    ResultHandlerOfSelectingDuplicateBarcode,
  } = useScanSku({
    setRowInfoToHighlight,
  });

  // 대기 중인 상태에서는 송장만, 작업 중인 상태에서는 상품만 스캔 가능
  const checkScannedResult = useCallback(
    (result: string) => {
      // isWorking 상태가 아닐 때 스캔 동작
      const actionAfterScanningWhenIsNotWorking = () => {
        switch (result) {
          case "NEXT_PAC":
          case "DEL_LAST_PAC":
          case "COM_PAC":
            break;

          case "H_GET_SHI_OUT":
          case "H_STA_PAC":
          case "O_CAN_INV": // 대기 중, 작업 중 둘 다 가능
          case "PRI_TEST_INV": // 대기 중, 작업 중 둘 다 가능
          case "PRI_ALL_INV": // 대기 중, 작업 중 둘 다 가능
          case "H_GET_INV_PRI": // 대기 중, 작업 중 둘 다 가능
          case "O_COM_INV":
            setCurrentActionBarcode(result);
            break;

          default:
            scanInvoice(result);
        }
      };

      const actionAfterScanningWhenIsWorking = () => {
        switch (result) {
          case "NEXT_PAC":
            if (isActiveNextPackingButton) setCurrentActionBarcode(result);
            break;

          case "DEL_LAST_PAC":
            if (isActiveDeleteLastPackingButton)
              setCurrentActionBarcode(result);
            break;

          case "COM_PAC":
            if (isActiveCompletePackingButton) setCurrentActionBarcode(result);
            break;

          case "H_INT_BAR":
          case "H_COM_PAC":
          case "H_DEL_LAST_PAC":
          case "H_ADD_NEXT_PAC":
          case "H_GET_CUS_OUT":
          case "H_REP_PRO":
          case "O_SCAN_SKU":
          case "O_COM_PAC":
          case "X_COM_PAC":
          case "O_SEL_OUT":
          case "O_DEL_LAST_PAC":
          case "X_DEL_LAST_PAC":
          case "O_DEL_PAD_SKU":
          case "X_DEL_PAD_SKU":
          case "O_CAN_INV":
          case "PRI_TEST_INV":
          case "PRI_ALL_INV":
          case "H_GET_INV_PRI":
          case "O_PRI_PAC_LAB":
          case "X_PRI_PAC_LAB":
            setCurrentActionBarcode(result);
            break;

          default:
            checkIsCorrectSkuBarcode(result);
        }
      };

      const isValidLength = IS_UNDER_PRODUCTION
        ? result.length > 6
        : result.length > 1;
      if (!isValidLength) {
        return;
      }

      if (!isWorking) {
        actionAfterScanningWhenIsNotWorking();
        return;
      }

      actionAfterScanningWhenIsWorking();
    },
    [
      isActiveCompletePackingButton,
      isActiveDeleteLastPackingButton,
      isActiveNextPackingButton,
      isWorking,
      scanInvoice,
      checkIsCorrectSkuBarcode,
      setCurrentActionBarcode,
    ]
  );

  useBrowserScan(checkScannedResult);

  return {
    rowInfoToHighlight,
    setRowInfoToHighlight,

    ErrorMessageOfScanningSkuModal,

    ResponseHandlerOfStartingPacking,
    ResponseHandlerOfCheckingCancelingInvoice,
    ResultHandlerOfSelectingDuplicateBarcode,
  };
}
