import { useCallback, useMemo, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import Modal from "@sellernote/_shared/src/componentsToMoveToV1/Modal";
import { TableRowInfoToHighlight } from "@sellernote/_shared/src/headlessComponents/table/useTable";
import PACKING_QUERY from "@sellernote/_shared/src/queries/fulfillment/PACKING_QUERY";
import {
  FULFILLMENT_PACKING_ATOMS,
  FULFILLMENT_PACKING_SELECTORS,
} from "@sellernote/_shared/src/states/fulfillment/packing";
import {
  getPackingInfoOfPackingNo,
  restoreInvoiceDetailList,
} from "@sellernote/_shared/src/utils/fulfillment/packing";

import useScanActionBarcode from "hooks/packing/useScanActionBarcode";

export default function useDeleteLastPacking({
  setRowInfoToHighlight,
}: {
  setRowInfoToHighlight: React.Dispatch<
    React.SetStateAction<TableRowInfoToHighlight | undefined>
  >;
}) {
  const [deleteModal, setDeleteModal] = useState<{
    title: React.ReactNode;
    body?: React.ReactNode;
  }>();

  const isActiveDeleteLastPackingButton = useRecoilValue(
    FULFILLMENT_PACKING_SELECTORS.IS_ACTIVE_DELETE_LAST_PACKING_BUTTON
  );
  const lastPackingNo = useRecoilValue(
    FULFILLMENT_PACKING_SELECTORS.LAST_PACKING_NO
  );
  const [packingInfoList, setPackingInfoList] = useRecoilState(
    FULFILLMENT_PACKING_ATOMS.PACKING_INFO_LIST
  );
  const setInvoiceDetailList = useSetRecoilState(
    FULFILLMENT_PACKING_ATOMS.INVOICE_DETAIL_LIST
  );

  const {
    mutate: deleteLastPacking,
    reset: resetDeleteLastPacking,
    ResponseHandler: ResponseHandlerOfDeletingLastPacking,
  } = PACKING_QUERY.useDeleteLastPacking({
    type: "lastPacking",
  });

  const handleDeleteModalOpen = useCallback(() => {
    if (lastPackingNo === 1) {
      setDeleteModal({
        title: (
          <>
            추가된 포장이 없습니다. <br /> 포장 1을 초기화 하시겠습니까?
          </>
        ),
      });
    } else {
      setDeleteModal({
        title: `최근포장(포장${lastPackingNo})`,
        body: (
          <>
            삭제하시겠습니까? <br /> 검수내역 또한 삭제됩니다.
          </>
        ),
      });
    }
  }, [lastPackingNo]);

  const handleLastPackingDelete = useCallback(() => {
    if (lastPackingNo === 1) {
      /** INVOICE_DETAIL_LIST 되돌리기 */
      const lastPackingDetailList = getPackingInfoOfPackingNo({
        packingInfoList,
        packingNo: "last",
      }).packingDetailList;
      const skuIdListToDelete = lastPackingDetailList.map(
        (lastPackingDetailItem) => lastPackingDetailItem.skuId
      );

      restoreInvoiceDetailList({
        packingDetailList: lastPackingDetailList,
        skuIdListToDelete,
        setInvoiceDetailList,
      });

      /** PACKING_INFO_LIST의 packingDetailList 되돌리기 */
      setPackingInfoList((prevPackingInfoList) => [
        {
          ...prevPackingInfoList[0],
          packingDetailList: [],
        },
      ]);

      setRowInfoToHighlight(undefined);
    } else {
      deleteLastPacking(undefined, {
        onSuccess: () => setRowInfoToHighlight(undefined),
      });
    }

    setDeleteModal(undefined);
  }, [
    deleteLastPacking,
    lastPackingNo,
    packingInfoList,
    setInvoiceDetailList,
    setPackingInfoList,
    setRowInfoToHighlight,
  ]);

  const DeleteLastPackingModal = useMemo(
    () => (
      <Modal
        barcodeValues={{
          actionPositive: "O_DEL_LAST_PAC",
          actionNegative: "X_DEL_LAST_PAC",
        }}
        uiType={deleteModal?.body ? "content" : "titleOnly"}
        active={!!deleteModal}
        title={deleteModal?.title}
        body={deleteModal?.body}
        actionPositive={{
          label: "네",
          handleClick: handleLastPackingDelete,
        }}
        actionNegative={{
          label: "아니오",
          handleClick: () => setDeleteModal(undefined),
        }}
      />
    ),
    [deleteModal, handleLastPackingDelete]
  );

  useScanActionBarcode({
    actionBarcode: "DEL_LAST_PAC",
    actionFn: handleDeleteModalOpen,
  });
  useScanActionBarcode({
    actionBarcode: "H_DEL_LAST_PAC",
    actionFn: resetDeleteLastPacking,
  });
  useScanActionBarcode({
    actionBarcode: "O_DEL_LAST_PAC",
    actionFn: handleLastPackingDelete,
  });
  useScanActionBarcode({
    actionBarcode: "X_DEL_LAST_PAC",
    actionFn: () => setDeleteModal(undefined),
  });

  return {
    isActiveDeleteLastPackingButton,
    handleDeleteModalOpen,

    DeleteLastPackingModal,

    ResponseHandlerOfDeletingLastPacking,
  };
}
