import { atom } from "recoil";

const KEY_PREFIX = "boful-worker-web/packing/atoms";

const IS_SCANNED_BARCODE_NOT_IN_PACKING_LIST = atom({
  key: `${KEY_PREFIX}/IS_SCANNED_BARCODE_NOT_IN_PACKING_LIST`,
  default: false,
});

export default {
  IS_SCANNED_BARCODE_NOT_IN_PACKING_LIST,
};
