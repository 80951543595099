import { FC, useEffect, useRef, useState } from "react";

import { useCheckIsTablet } from "../../../utils/common/hook";

import useTableCurtain from "./useTableCurtain";

type TableProps<T> =
  | (TableBasicProps<T> & { isWindowed?: false })
  | (TableBasicProps<T> & { isWindowed: true; height: number });
type TableComponent<T> = FC<TableProps<T>>;

/**
 * 특정 Td의 border-bottom을 없애기 위해서 사용
 * 반드시 값이 들어가는 key(테이블에 표시할 데이터)보다 상단에 작성해야 함
 */
interface NoBorderBottomForTd {
  key: string; // column 이름
  isLastRow: boolean; // 마지막 Row에는 border-bottom이 있어야 함
}

/**
 * 특정 Row의 컬럼을 합쳐서 데이터를 입력하고 싶을 경우 사용
 */
interface ColSpan {
  value: number;
  content: React.ReactNode;
  hasFullWidth?: boolean;
}

/**
 * Th를 Grouping하기 위해 사용
 */
type TableColumnGroupInfo = TableColumnInfoValue & {
  /**
   * th의 좌, 우측 끝에는 curtain이 있기 때문에
   * 좌, 우측 끝 Th group에는 원하는 colSpan+1을 해줘야 함
   */
  colSpan: number;
};

type TableDataListItem<T> = {
  [P in keyof T]: T[P];
} & {
  rowKey: string | number;
  /**
   * 특정 row의 글자색을 다르게 하고 싶을 경우 HEX값으로 전달(COLOR 상수 이용)
   */
  rowFontColor?: string;
  rowBackgroundColor?: string;
  handleRowClick?: () => void;
  disabled?: boolean;
  rowClassName?: string;
  /**
   * border-bottom 효과를 없애고 싶을때 사용
   */
  noBorderBottom?: boolean;
  colSpan?: ColSpan;
  noBorderBottomForTd?: NoBorderBottomForTd[];
  /**
   * 특정 Row의 hover 배경컬러를 무시하고 싶을 때 사용
   */
  disabledRowHoverBgColor?: boolean;
  /**
   * Row가 하위행인 경우
   */
  isSubRow?: boolean;
};

type TableColumnInfoValue = {
  label: React.ReactNode;
  /**
   * flex 비율. default: 1
   * web에서는 반응형 구현을 위해서 portion을 사용
   */
  portion?: number;
  /**
   * 최소 너비를 설정. fixedWidth 속성이 있을때는 무시됨
   */
  minWidth?: number;
  /**
   * 최대 너비를 제한. fixedWidth 속성이 있을때는 무시됨
   */
  maxWidth?: string;
  /**
   * width에 고정 px를 주고 싶을때 사용
   * - table width 가 길어져서 가로스크롤이 필요할 때, 총 너비가 아닌 화면에 노출되어야 하는 너비를 지정한다.
   * - fixedWidth가 할당되면 portion은 무효화 됨.
   * - 하나 이상의 column에 fixedWidth이 할당되면 나머지 column은 기본 width가 80px로 지정됨
   * - device width가 좁으면 overflow됨에 유의
   */
  fixedWidth?: number;
};

type TableColumnInfo<T> = {
  [P in keyof T]: TableColumnInfoValue;
};

interface TableRowInfoToHighlight {
  rowKey: string | number;
}

type TableSubRowsInfoToHighlight = TableRowInfoToHighlight[];

interface TableBasicProps<T> {
  columnGroupInfo?: TableColumnGroupInfo[];
  columnInfo: TableColumnInfo<T>;
  dataList?: TableDataListItem<T>[];
  setTestMounted?: (val: boolean) => void;
  /**
   * 한 행의 하이라이팅 & 스크롤 이동을 위한 rowKey 정보
   */
  rowInfoToHighlight?: TableRowInfoToHighlight;
  /**
   * 여러 개 행의 하이라이팅을 위한 정보(ex. 그룹상품의 경우 그룹상품 관련 행 전체가 하이라이팅)
   */
  subRowsInfoToHighlight?: TableSubRowsInfoToHighlight;
  /**
   * row의 최소 높이. (*헤드 row에는 반영되지 않음)
   */
  rowMinHeight?: string;
  /**
   * 가로 스크롤이 필요한 경우
   */
  isHorizontalScrollable?: boolean;
  /**
   * 특정 Row의 hover 배경컬러를 무시하고 싶을 때 사용
   */
  disabledRowHoverBgColor?: boolean;
  className?: string;
}

type RowToHighlightRef =
  | ((instance: HTMLTableRowElement | null) => void)
  | React.MutableRefObject<HTMLTableRowElement | null>
  | null;

type TablePropsWithRef<T> = TableProps<T> & {
  /**
   * 부모컴포넌트로부터 전달받는 ref로서,
   * rowInfoToHighlight에 따라 highlight할 row가 되는 dom요소를 가져오는데 사용됨.
   */
  ref?: RowToHighlightRef;
};

function useTable<T>({
  columnGroupInfo,
  columnInfo,
  rowInfoToHighlight,
}: TableProps<T>) {
  const { isTablet } = useCheckIsTablet();

  const columnGroupSizes = (columnGroupInfo ?? []).map((v) => v.colSpan);

  const hasFixedWidth = Object.values(columnInfo).some(
    (v) => !!(v as TableColumnInfoValue).fixedWidth
  );

  const isOverflowed = hasFixedWidth;

  const [isBlinkOnHighlightRow, setIsBlinkOnHighlightRow] = useState(
    !!rowInfoToHighlight
  );

  const tableRef = useRef(null as unknown as HTMLTableElement);

  const tableLeftEndRef = useRef(null as unknown as HTMLTableCellElement);
  const tableRightEndRef = useRef(null as unknown as HTMLTableCellElement);

  const { tableHasArriveLeftEnd, tableHasArriveRightEnd, onScrollTable } =
    useTableCurtain({
      activated: isOverflowed && !isTablet,
      table: tableRef.current,
      leftEnd: tableLeftEndRef.current,
      rightEnd: tableRightEndRef.current,
    });

  useEffect(() => {
    if (rowInfoToHighlight) {
      handleHighlightInfoChange();
    }

    function handleHighlightInfoChange() {
      setIsBlinkOnHighlightRow(true);
      setTimeout(() => {
        setIsBlinkOnHighlightRow(false);
      }, 1000);
    }
  }, [rowInfoToHighlight]);

  return {
    tableRef,
    tableLeftEndRef,
    tableRightEndRef,
    tableHasArriveLeftEnd,
    tableHasArriveRightEnd,
    onScrollTable,
    isBlinkOnHighlightRow,
    isOverflowed,
    columnGroupSizes,
  };
}

export default useTable;

export type {
  TableProps,
  NoBorderBottomForTd,
  ColSpan,
  RowToHighlightRef,
  TableDataListItem,
  TableColumnInfo,
  TableRowInfoToHighlight,
  TableSubRowsInfoToHighlight,
  TablePropsWithRef,
  TableColumnInfoValue,
  TableComponent,
};
