import { useCallback, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";

import PACKING_QUERY from "@sellernote/_shared/src/queries/fulfillment/PACKING_QUERY";
import { FULFILLMENT_PACKING_ATOMS } from "@sellernote/_shared/src/states/fulfillment/packing";

export default function useInvoiceDirectInput() {
  const [
    isVisibleInvoiceDirectInputModal,
    setIsVisibleInvoiceDirectInputModal,
  ] = useState(false);
  const [invoiceNo, setInvoiceNo] = useState("");

  const isWorking = useRecoilValue(FULFILLMENT_PACKING_ATOMS.IS_WORKING);
  const setCanceledInvoiceNo = useSetRecoilState(
    FULFILLMENT_PACKING_ATOMS.CANCELED_INVOICE_NO
  );

  const {
    mutate: startPacking,
    ResponseHandler: ResponseHandlerOfStartingPacking,
    reset,
  } = PACKING_QUERY.useStartPacking({
    type: "directInput",
  });

  const handleDirectInputModalOpen = useCallback(() => {
    setIsVisibleInvoiceDirectInputModal(true);
  }, []);

  const handleDirectInputModalClose = useCallback(() => {
    setIsVisibleInvoiceDirectInputModal(false);
    setInvoiceNo("");
  }, []);

  const handleDirectInputModalConfirm = useCallback(() => {
    startPacking(
      { pathParams: { invoiceNo } },
      {
        onError: ({ response }) => {
          const failureInfo = response?.data;
          if (failureInfo?.code === 400 && failureInfo.errorCode === "E4002") {
            reset(); // ResponseHandler의 에러 모달 대신 취소 전용 에러 모달을 보여주기 위함
            setCanceledInvoiceNo(invoiceNo);
          }
        },
      }
    );

    handleDirectInputModalClose();
  }, [
    handleDirectInputModalClose,
    invoiceNo,
    reset,
    setCanceledInvoiceNo,
    startPacking,
  ]);

  return {
    isActiveInvoiceDirectInputButton: !isWorking,

    isVisibleInvoiceDirectInputModal,

    invoiceNo,
    setInvoiceNo,

    handleDirectInputModalOpen,
    handleDirectInputModalClose,
    handleDirectInputModalConfirm,

    ResponseHandlerOfStartingPacking,
  };
}
