import { APP_BUILD_INFO } from "@sellernote/_shared/src/constants";
import { TableRowInfoToHighlight } from "@sellernote/_shared/src/headlessComponents/table/useTable";
import { toFormattedDate } from "@sellernote/_shared/src/utils/common/date";

import withRequireAuth from "hocs/withRequireAuth";

import CompletePacking from "./CompletePacking";
import DeleteLastPacking from "./DeleteLastPacking";
import InvoiceDetail from "./InvoiceDetail";
import InvoiceDirectInput from "./InvoiceDirectInput";
import InvoiceSummary from "./InvoiceSummary";
import NextPacking from "./NextPacking";
import OutSidePackage from "./OutSidePackage";
import PackingDetail from "./PackingDetail";
import PrintInvoice from "./PrintInvoice";
import PrintPackingLabel from "./PrintPackingLabel";
import ReportProblem from "./ReportProblem";
import RequestMemo from "./RequestMemo";
import StatusBar from "./StatusBar";
import useCanceledInvoice from "./useCanceledInvoice";
import useFetchOutSidePackageOfShipda from "./useFetchOutSidePackageOfShipda";
import usePacking from "./usePacking";
import useScanCompletedInvoice from "./useScanCompletedInvoice";
import VideoRecording from "./VideoRecording";
import WarningAudio from "./WarningAudio";
import Styled from "./index.styles";

function InvoiceInfo({
  rowInfoToHighlight,
}: {
  rowInfoToHighlight: TableRowInfoToHighlight | undefined;
}) {
  return (
    <Styled.invoiceInfoContainer>
      <div className="summary">
        <InvoiceDirectInput />
        <InvoiceSummary />
        <OutSidePackage />
        <NextPacking />
      </div>

      <div className="detail">
        <InvoiceDetail rowInfoToHighlight={rowInfoToHighlight} />
      </div>
    </Styled.invoiceInfoContainer>
  );
}

function Packing() {
  const { ResponseHandlerOfGettingOutSidePackageListOfShipda } =
    useFetchOutSidePackageOfShipda();

  const {
    rowInfoToHighlight,
    setRowInfoToHighlight,

    ErrorMessageOfScanningSkuModal,

    ResponseHandlerOfStartingPacking,
    ResponseHandlerOfCheckingCancelingInvoice,
    ResultHandlerOfSelectingDuplicateBarcode,
  } = usePacking();

  const { CanceledInvoiceModal } = useCanceledInvoice();

  const { CompletedInvoiceModal } = useScanCompletedInvoice();

  return (
    <Styled.container>
      <StatusBar />

      <Styled.mainContainer>
        <div className="header">
          <div className="title">패킹</div>
          <PrintPackingLabel />
        </div>

        <div className="body">
          <div className="info">
            <InvoiceInfo rowInfoToHighlight={rowInfoToHighlight} />

            <PackingDetail
              rowInfoToHighlight={rowInfoToHighlight}
              setRowInfoToHighlight={setRowInfoToHighlight}
            />
          </div>

          <div className="action">
            <VideoRecording />
            <ReportProblem setRowInfoToHighlight={setRowInfoToHighlight} />
            <PrintInvoice />
            <DeleteLastPacking setRowInfoToHighlight={setRowInfoToHighlight} />
            <CompletePacking setRowInfoToHighlight={setRowInfoToHighlight} />
            <RequestMemo />

            {APP_BUILD_INFO && (
              <div className="app-build-info">
                <div className="built-at">
                  {`빌드: ${toFormattedDate(
                    APP_BUILD_INFO.builtAt,
                    "YYYY.MM.DD HH:mm:ss"
                  )}`}
                </div>

                <div className="hidden">{APP_BUILD_INFO.gitBranch}</div>

                <div className="hidden">{APP_BUILD_INFO.gitCommitSha}</div>
              </div>
            )}
          </div>
        </div>
      </Styled.mainContainer>

      {ErrorMessageOfScanningSkuModal}
      {CanceledInvoiceModal}
      {CompletedInvoiceModal}

      {ResponseHandlerOfGettingOutSidePackageListOfShipda}
      {ResponseHandlerOfStartingPacking}
      {ResponseHandlerOfCheckingCancelingInvoice}
      {ResultHandlerOfSelectingDuplicateBarcode}

      <WarningAudio />
    </Styled.container>
  );
}

export default withRequireAuth(Packing);
